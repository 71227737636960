import React, { useEffect, useState } from "react"
import styled from "styled-components"
import gsap from "gsap"
import ReactPlayer from "react-player"
import Section from "components/shared/Section"
import OrangeLine from "components/shared/OrangeLine"
import {media} from "utils/Media";

const Section1 = styled(Section)`
    height: 100vh;
    z-index: 6;
    flex-wrap: wrap;
    overflow: hidden;

    .scrollMore {
        padding: 1rem 0;
        position: absolute;
        bottom: 4rem;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
        text-transform: uppercase;
        font-size: 12px;

         @media ${media.md} {
            font-size: 16px;
         }
    }
`

const VideoWrap = styled.div`
    /* padding-bottom: 56.25%; */
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;

    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
    }

    .player {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 120% !important;
        height: 120% !important;
    }
`

const Section1Landing = () =>  {
    const [playing, playVideo] = useState(true);

    useEffect(() => {

        /*
            Todo: Stop video when scroll out? Play when scroll back in
        */

		// gsap.timeline( {
		// 	scrollTrigger: {
		// 		trigger: ".s1",
		// 		// scrub: true,
		// 		start: "top top",
		// 		// end: "bottom top",
		// 		scroller: ".scroller",
		// 		// markers: true,
		// 		// onUpdate: self =>  this.player.seekTo(parseFloat(self.progress.toFixed(3))),
		// 		// onEnter: () => setPlaying(true),
        //         onLeave: () => setPlaying(false),
		// 		onEnterBack: () => setPlaying(true),
		// 		onLeaveBack: () => setPlaying(false),
        //         // onToggle: self => self.isActive ? setPlaying(true) : setPlaying(false),
		// 	}
		// })

        gsap.timeline({
			scrollTrigger: {
				trigger: ".s1",
				scrub: 0.5,
				start: "top 50px",
				scroller: ".scroller",
				// markers: true,
			},

		})
			.to(".ol1", { height: "300px"} )
			.to(".ol1", { height: "100px"} )
    }, [])

    return(
        <Section1 className="s1">
            <VideoWrap>
                <ReactPlayer
                    url="//vimeo.com/516685786/df248d2dbe"
                    muted={true}
                    controls={false}
                    playsinline={true}
                    playing={playing}
                    loop={true}
                    className="player"

                />
            </VideoWrap>

            <div className="scrollMore">
                Scroll to view more
            </div>

            <OrangeLine className="ol1" />
        </Section1>
    )

}

export default Section1Landing
