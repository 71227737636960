import React, {useEffect, useRef, useState} from "react"
import styled from "styled-components"
import Section from "../shared/Section"
import Lottie from 'react-lottie-player'
import animationData from "../../bodymovin/intro-text/data.json";
import animationDataMobile from "../../bodymovin/intro-text-mob/data.json";
import gsap from "gsap";
import {media} from "utils/Media"

const settings = {
    loop: false,
    autoplay: true,
    renderer: 'svg',
};

const Section2 = styled(Section)`
    background-color: #000000;
    z-index: 5;
    min-height: 40vh;
    align-items: flex-end;
    
    @media ${media.md} {
        min-height: 550px;
    }
`

const Section2Text = () => {

    const [animationPosition, setAnimationPosition] = useState(0);
    const lottieRef = useRef();

    useEffect(() => {
        gsap.timeline({
            scrollTrigger: {
                trigger: ".s2",
                pin: false,
                scrub: true,
                start: "top 50%",
                end: "bottom 70%",
                scroller: ".scroller",
                // markers: {startColor: "yellow", endColor: "yellow"},
                onUpdate: self => {
                    setAnimationPosition(100 * self.progress + 20);
                }
                // markers: true,
            }
        })
            .fromTo("#scrambleText", {opacity: 0}, {opacity: 1})
            .to("#scrambleText", {opacity: 1})
    }, [])


    // console.log(isVisible)
    return (
        <Section2 className="s2">
                <div id="scrambleText">
                    <div className="d-block d-sm-none">
                        <Lottie
                            animationData={animationDataMobile}
                            options={settings}
                            loop={false}
                            goTo={animationPosition}
                        />
                    </div>
                    <div ref={lottieRef} className="d-none d-sm-block">
                        <Lottie
                            animationData={animationData}
                            options={settings}
                            loop={false}
                            goTo={animationPosition}
                        />
                    </div>
                </div>
        </Section2>
    )
}


export default Section2Text