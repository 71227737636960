import styled from "styled-components"

const OrangeLine = styled.div`
	position: absolute;
	width: 0;
	height: 50px;
	left: 50%;
	transform: translateX(-50%);
	top: calc(100% + -50px);
	z-index: 2;

	border: 1px solid #FFAA00;
	border-radius: 5px;
	box-shadow: 0px 0px 7px #ff3c00, 0px 0px 7px #ff3c00, 0px 0px 7px #ff3c00;

	&.ol2 {
	    opacity: 0;
	    height: 0;
		top: calc(100% - 200px);
	}

	&.ol3 {
		height: 0;
		position: absolute;
		top: calc(100% + 2rem);
		opacity: 0;
	}

	&.ol4 {
		height: 0;
		position: relative;
		top: 4rem;
		/* opacity: 0; */
	}
`

export default OrangeLine