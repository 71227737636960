import React from "react"
import { Link, StaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { Container, Row, Col } from "reactstrap"
import Img from "gatsby-image"
import { media } from "utils/Media"
import line from "images/underline.svg"

const Section = styled.div`
    padding: 4rem 0;
    overflow: hidden;
    position: relative;
    
    @media ${media.lg} {
        padding: 8rem 0;
    }

    .woodBg {
        position: absolute !important;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100% !important;
        max-width: 950px;
        height: 100%;
    }
`

const ContainerStyled = styled(Container)`
    position: relative;
    z-index: 2;
    text-align: center;
    text-transform: uppercase;

    h3 {
        font-size: 2rem;
        padding-bottom: 2rem;
        @media ${media.md} {
            font-size: 3.5rem;
        }
    }

    a {
        font-size: 1.5rem;
        line-height: 1.1;
        position: relative;
        display: flex;

        &:hover {
            text-decoration: none;
        }
    }
`

const ColStyled = styled(Col)`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding-bottom: 2rem;

    img {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
    }
`

const SectionAdditionalInfo = (props) => {
    return(
        <Section>
            <Img fluid={props.data.woodBg.childImageSharp.fluid} alt="" className="woodBg" />

            <ContainerStyled>
                <h3>Additional Information</h3>
                <Row>
                    <ColStyled sm={6} md={3} className="mb-3 mb-md-0">
                        <Link to="/additional-information/#international-success/">
                            International<br/> success
                        </Link>
                        <img src={line} alt="" />
                    </ColStyled>
                    <ColStyled sm={6} md={3} className="mb-3 mb-md-0">
                        <Link to="/additional-information#about-the-play/">
                            About the play
                        </Link>
                        <img src={line} alt="" />
                    </ColStyled>
                    <ColStyled sm={6} md={3} className="mb-3 mb-md-0">
                        <Link to="/additional-information#production-design/">
                            Production<br/> design
                        </Link>
                        <img src={line} alt="" />
                    </ColStyled>
                    <ColStyled sm={6} md={3} className="mb-3 mb-md-0">
                        <Link to="/additional-information#licensing-and-materials/">
                            Licensing and<br/> Materials
                        </Link>
                        <img src={line} alt="" />
                    </ColStyled>
                </Row>
            </ContainerStyled>
        </Section>
    )
}

export default () => (
    <StaticQuery
        query={graphql`
            query {
                woodBg: file(relativePath: { eq: "wood-bg.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 1920, quality: 85) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
		`}
        render={data => (
            <SectionAdditionalInfo data={data}/>
        )}
    />
)