import React, {useEffect} from "react"
import styled from "styled-components"
import gsap from "gsap"
import {StaticQuery, graphql} from "gatsby"
import {Container, Row, Col} from "reactstrap"
import Img from "gatsby-image"
import Section from "components/shared/Section"
import {media} from "utils/Media";

const Section7 = styled(Section)`
    overflow: hidden;
    z-index: 5;
    top: 10vw;
    display: block;
    padding-top: 0;
    max-width: 100vw;
    min-height: 200vh;
    height: 120vh;

    .woodBg2Md {
        position: absolute !important;
        top: 50%;
        left: -25%;
        transform: translateY(-50%);
        width: 150%;
        z-index: 1;
        
        @media ${media.sm} {
            left: 50%;
            transform: translate(-50%, -50%);
            width: 150% !important;
        }
    }

    .penContentMd {
        width: 500px;
        opacity: 0;
    }
    
    .penContentMd2 {
        width: 500px;
        opacity: 0;
    }
    
    .penContentMd3 {
        width: 500px;
        opacity: 0;
    }
`

const Content = styled.div`
    position: relative;
    z-index: 2;
`
const Relative = styled.div`
    position: relative;
`
const SVGWrapper = styled.div`
    margin: 0 auto;
    width: 100%;
    text-align: center;
    
    .pen-md-top-wrapper {
        position: absolute;
        left: -103px;
        top: 0;
    }
    
    .pen-md-bottom-wrapper {
        position: absolute;
        top: 0;
        left: 50%;
    }
`

const Section7PenTablet = (props) => {

    useEffect(() => {
        // Pen line
        gsap.timeline({
            scrollTrigger: {
                trigger: ".s7Md",
                scrub: 1,
                pin: false,
                start: "top 30%",
                end: "+=100%",
                // markers: {startColor: "pink", endColor: "yellow"},
                scroller: ".scroller",
            }
        })
            .fromTo(".pen-md", {drawSVG: "0% 0%"}, {drawSVG: "0% 100%"}, 0)
            .fromTo(".pen-md-top", {drawSVG: "0% 0%"}, {drawSVG: "0% 100%"}, 0.1)
            .fromTo(".woodBg2Md", {opacity: "0"}, {opacity: "1"}, 0.2)
            .fromTo(".penContentMd", {opacity: "0"}, {opacity: "1"}, 0.3)
            .fromTo(".pen-md-middle", {drawSVG: "0% 0%"}, {drawSVG: "0% 100%"}, 0.4)
            .fromTo(".penContentMd2", {opacity: "0"}, {opacity: "1"}, 0.5)
            .fromTo(".pen-md-bottom", {drawSVG: "0% 0%"}, {drawSVG: "0% 100%"}, 0.6)
            .fromTo(".penContentMd3", {opacity: "0"}, {opacity: "1"}, 0.7)
    }, [])

    return (
        <Section7 className="s7Md">
            <Img fluid={props.data.woodBg.childImageSharp.fluid} alt="" className="woodBg2Md"/>
            <Content>

                <SVGWrapper style={{paddingBottom: "70px"}}>
                    <svg width="120" height="295" viewBox="0 0 120 295" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g filter="url(#filter7_ddd1)">
                            <path className="pen-md"
                                  d="M63 14V87H90C90 108 89.8408 135.12 94 155.5C99 180 104 191.333 106 197C101 203 89.4 219.2 83 236C76.6 252.8 71.6667 273 70 281H63V197C65.3333 195.833 70 192.1 70 186.5C70 180.9 66 176.5 60 176.5C54 176.5 50 180.5 50 186.5C50 192.5 54 195.5 56 197V281H49C45.8333 267.167 38 236.9 32 226.5C26 216.1 17.1667 202.5 13.5 197C16.8333 186.833 23.9 163.9 25.5 153.5C27.1 143.1 30 111 29 87"
                                  stroke="#FFAA00" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                        </g>
                        <defs>
                            <filter id="filter7_ddd1" x="0" y="0.5" width="119.5" height="294"
                                    filterUnits="userSpaceOnUse"
                                    colorInterpolationFilters="sRGB">
                                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                                <feColorMatrix in="SourceAlpha" type="matrix"
                                               values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                                <feOffset/>
                                <feGaussianBlur stdDeviation="6"/>
                                <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.234375 0 0 0 0 0 0 0 0 1 0"/>
                                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                                <feColorMatrix in="SourceAlpha" type="matrix"
                                               values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                                <feOffset/>
                                <feGaussianBlur stdDeviation="3"/>
                                <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.234375 0 0 0 0 0 0 0 0 1 0"/>
                                <feBlend mode="normal" in2="effect1_dropShadow" result="effect2_dropShadow"/>
                                <feColorMatrix in="SourceAlpha" type="matrix"
                                               values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                                <feOffset/>
                                <feGaussianBlur stdDeviation="2"/>
                                <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.234375 0 0 0 0 0 0 0 0 1 0"/>
                                <feBlend mode="normal" in2="effect2_dropShadow" result="effect3_dropShadow"/>
                                <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow" result="shape"/>
                            </filter>
                        </defs>
                    </svg>
                    <Relative>
                        <svg className="pen-md-top-wrapper" width="507" height="59" viewBox="0 0 507 59" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <g filter="url(#filter7_ddd2)">
                                <path className="pen-md-top"
                                      d="M492.177 13C497.624 20.4774 494.045 37.177 436.158 44.156C363.8 52.8797 228.42 27.9549 160.731 25.4624C106.579 23.4684 13.6803 30.4474 -26 34.1861"
                                      stroke="#FFAA00" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </g>
                            <defs>
                                <filter id="filter7_ddd2" x="-39" y="0" width="546" height="58.9998"
                                        filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                                    <feColorMatrix in="SourceAlpha" type="matrix"
                                                   values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                                    <feOffset/>
                                    <feGaussianBlur stdDeviation="6"/>
                                    <feColorMatrix type="matrix"
                                                   values="0 0 0 0 1 0 0 0 0 0.234375 0 0 0 0 0 0 0 0 1 0"/>
                                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                                    <feColorMatrix in="SourceAlpha" type="matrix"
                                                   values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                                    <feOffset/>
                                    <feGaussianBlur stdDeviation="3"/>
                                    <feColorMatrix type="matrix"
                                                   values="0 0 0 0 1 0 0 0 0 0.234375 0 0 0 0 0 0 0 0 1 0"/>
                                    <feBlend mode="normal" in2="effect1_dropShadow" result="effect2_dropShadow"/>
                                    <feColorMatrix in="SourceAlpha" type="matrix"
                                                   values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                                    <feOffset/>
                                    <feGaussianBlur stdDeviation="2"/>
                                    <feColorMatrix type="matrix"
                                                   values="0 0 0 0 1 0 0 0 0 0.234375 0 0 0 0 0 0 0 0 1 0"/>
                                    <feBlend mode="normal" in2="effect2_dropShadow" result="effect3_dropShadow"/>
                                    <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow" result="shape"/>
                                </filter>
                            </defs>
                        </svg>
                    </Relative>
                </SVGWrapper>

                <Container className="penContentMd narrow">
                    <div className="justify-content-center d-flex">
                        <div className="content">
                            <p>Agatha Christie is the world’s best-selling novelist of all time, with an estimated 2
                                billion books sold. Besides having written the world’s longest-running play, she is also
                                the only female playwright to have ever had three plays running simultaneously in
                                London’s West End. Her books and plays have been adapted into countless TV series and
                                award-winning films including Death on the Nile, which returns later this year in a
                                remake starring Kenneth Branagh and Annette Bening.</p>
                        </div>
                    </div>
                </Container>

                <SVGWrapper style={{paddingBottom: "30px"}}>
                    <svg width="991" height="111" viewBox="0 0 991 111" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g filter="url(#filter7_ddd3)">
                            <path className="pen-md-middle"
                                  d="M-3 18.1829C116.735 37.1819 383.719 74.1886 493.774 70.2236C631.342 65.2674 552.368 -6.59845 455.56 18.1829C358.753 42.9642 414.799 80.1361 572.748 92.5268C699.107 102.439 902.232 96.657 988 92.5268"
                                  stroke="#FFAA00" strokeWidth="2" strokeLinecap="round"/>
                        </g>
                        <defs>
                            <filter id="filter7_ddd3" x="-16" y="0" width="1017" height="111"
                                    filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                                <feColorMatrix in="SourceAlpha" type="matrix"
                                               values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                                <feOffset/>
                                <feGaussianBlur stdDeviation="6"/>
                                <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.234375 0 0 0 0 0 0 0 0 1 0"/>
                                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                                <feColorMatrix in="SourceAlpha" type="matrix"
                                               values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                                <feOffset/>
                                <feGaussianBlur stdDeviation="3"/>
                                <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.234375 0 0 0 0 0 0 0 0 1 0"/>
                                <feBlend mode="normal" in2="effect1_dropShadow" result="effect2_dropShadow"/>
                                <feColorMatrix in="SourceAlpha" type="matrix"
                                               values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                                <feOffset/>
                                <feGaussianBlur stdDeviation="2"/>
                                <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.234375 0 0 0 0 0 0 0 0 1 0"/>
                                <feBlend mode="normal" in2="effect2_dropShadow" result="effect3_dropShadow"/>
                                <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow" result="shape"/>
                            </filter>
                        </defs>
                    </svg>

                </SVGWrapper>

                <Container className="penContentMd2 narrow">
                    <div className="justify-content-center d-flex">
                        <div className="content">
                            <p>And yet over the years, THE MOUSETRAP has become more than just
                                a play. It has evolved
                                into a can’t-miss destination for visitors from every corner of the globe, a joyful rite
                                of passage for theatregoers of all kinds, and an enduring family tradition shared across
                                generations. </p>
                        </div>
                    </div>
                </Container>

                <SVGWrapper style={{height: "130px"}}>
                    <Relative>
                        <svg className="pen-md-bottom-wrapper" width="511" height="121" viewBox="0 0 511 121" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g filter="url(#filter7_ddd4)">
                                <path className="pen-md-bottom" d="M513 13C461.592 22.5833 341.757 42.75 273.685 46.75C188.595 51.75 98.1875 20.5 47.6654 28C9.39164 33.6817 13.0975 46.75 13.0976 108" stroke="#FFAA00" strokeWidth="2" strokeLinecap="round"/>
                            </g>
                            <defs>
                                <filter id="filter7_ddd4" x="0" y="0" width="526" height="121" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                                    <feOffset/>
                                    <feGaussianBlur stdDeviation="6"/>
                                    <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.234375 0 0 0 0 0 0 0 0 1 0"/>
                                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                                    <feOffset/>
                                    <feGaussianBlur stdDeviation="3"/>
                                    <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.234375 0 0 0 0 0 0 0 0 1 0"/>
                                    <feBlend mode="normal" in2="effect1_dropShadow" result="effect2_dropShadow"/>
                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                                    <feOffset/>
                                    <feGaussianBlur stdDeviation="2"/>
                                    <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.234375 0 0 0 0 0 0 0 0 1 0"/>
                                    <feBlend mode="normal" in2="effect2_dropShadow" result="effect3_dropShadow"/>
                                    <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow" result="shape"/>
                                </filter>
                            </defs>
                        </svg>


                    </Relative>

                </SVGWrapper>


                <Container className="penContentMd3 narrow">
                    <div className="justify-content-center d-flex">
                        <div className="content">
                                <p>Perhaps the reason THE MOUSETRAP appeals to a wide range of audiences and age groups lies
                                    in its clever dramatic formula. The iconic set-up, which has inspired countless other
                                    murder mysteries, puts audience members in the position of not just passive observers,
                                    but active participants.</p>
                            </div>
                    </div>
                </Container>
            </Content>

        </Section7>
    )
}


export default () => (
    <StaticQuery
        query={graphql`
            query {
                woodBg: file(relativePath: { eq: "wood-bg.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 1920, quality: 85) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
		`}
        render={data => (
            <Section7PenTablet data={data}/>
        )}
    />
)