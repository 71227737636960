import React from "react"
import Layout from "../components/Layout/Layout"
import SEO from "utils/Seo"

import Section1Landing from "../components/Sections/Section1Landing"
import Section2Text from "../components/Sections/Section2Text"
import Section3YearCount from "../components/Sections/Section3YearCount"
import Section4Map from "../components/Sections/Section4Map"
import Section6Magnify from "../components/Sections/Section6Magnify"
import Section7Pen from "../components/Sections/Section7Pen"
import Section7PenTablet from "../components/Sections/Section7PenTablet"
import Section8GridLines from "../components/Sections/Section8GridLines"
import Section10Joining from "../components/Sections/Section10Joining"
import Section11ReachOut from "../components/Sections/Section11ReachOut"
import Section13AdamSpiegel from "../components/Sections/Section13AdamSpiegel"
import Section13AdamSpiegelTablet from "../components/Sections/Section13AdamSpiegelTablet"
import SectionAdditionalInfo from "../components/Sections/SectionAdditionalInfo"
import Footer from "../components/Layout/Footer"

const IndexPage = () => {
	return(
		<Layout>
			<SEO title="Home" />

			<Section1Landing />
			<Section2Text />
			<Section3YearCount />
			<Section4Map />
			<Section6Magnify />
			<div className="d-none d-sm-block d-lg-none">
				<Section7PenTablet />
			</div>
			<div className="d-none d-lg-block">
				<Section7Pen />
			</div>
			<Section8GridLines />
			<Section10Joining />
			<Section11ReachOut />
			<div className="d-none d-sm-block d-lg-none">
				<Section13AdamSpiegelTablet />
			</div>
			<div className="d-none d-lg-block">
				<Section13AdamSpiegel />
			</div>
			<SectionAdditionalInfo />

			<Footer/>

			{/*
			<div className="section s10"></div>
			<div className="section s11"></div>
			<div className="section s12"></div> */}
		</Layout>
	)
}

export default IndexPage