import React, {useState, useEffect, useRef} from "react"
import {Container} from "reactstrap"
import ReactPlayer from "react-player"
import gsap from "gsap"
import styled from "styled-components"
import Section from "../shared/Section"
import {media} from "utils/Media"

const Section10 = styled(Section)`
 background-color: #000;
    z-index: 4;
    display: block;
    min-height: 40vw;
    overflow: hidden;

    .joiningContent {
        .content {
            margin-top: 50px;
            padding-left: 28px;
            width: calc(100% - 60px);

            @media ${media.sm} {
                padding-left: 0;
                max-width: 700px;
                width: 100%;
            }
        }
    }
`

const Section9 = styled(Section)`
    background-color: #000;
    min-height: 100vw;
    overflow: hidden;
    position: relative;
    width: 100%;

    @media ${media.md} {
        min-height: 40vw;
    }

    .videoWrap {
        position: absolute;
        left: 50%;
        top: -25%;
        /* transform: translateX(-50%); */
        width: 150%;
        height: 150%;
        pointer-events: none;
        z-index: 10;
    }
`

const OrangeLineWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
`

const SVGWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-end;
    #by {

        @media ${media.sm} {
            width: 220px;
        }
        @media ${media.md} {
            width: 200px;
        }
    }

    #j {
        @media ${media.sm} {
            width: 46px;
        }
        @media ${media.md} {
            width: 57px;
        }

        @media ${media.lg} {
            width: 100%;
        }
    }
    #oin {
        padding-left: 2px;
        @media ${media.sm} {
            padding-left: 5px;
        }
    }
    #ing {
        padding-left: 2px;
        @media ${media.sm} {
            padding-left: 10px;
        }
    }

    svg {
        @media (max-width: 575px) {
            height: 38px;
            width: auto;
        }
    }
`

const Section10Joining = () => {
    const [playing, setPlaying] = useState(false);
    const player = useRef(null)
    const by = useRef(null)
    const j = useRef(null)
    const oin = useRef(null)
    const ing = useRef(null)

    useEffect(() => {
        // // Video animation
        gsap.timeline({
            scrollTrigger: {
                trigger: ".s9",
                scrub: true,
                start: "top top",
                end: "bottom top",
                scroller: ".scroller"
            }
        })
            .to(".s9", {yPercent: "-100"})

        gsap.timeline({
            scrollTrigger: {
                trigger: ".s9",
                scrub: true,
                start: "top top",
                end: "bottom top",
                scroller: ".scroller"
            }
        })
            .to(".svgWrapper", {yPercent: "-75"})

        gsap.timeline({
            scrollTrigger: {
                trigger: ".s9",
                scrub: true,
                start: "top bottom",
                end: "bottom top",
                scroller: ".scroller",
                // onUpdate: self =>  this.player.seekTo(parseFloat(self.progress.toFixed(3))),
                onEnter: () => setPlaying(true),
                onEnterBack: () => setPlaying(true),
                onLeaveBack: () => setPlaying(false),
                onLeave: () => setPlaying(false)
            }
        })
            .fromTo(".video2", {yPercent: "-25", x: "-50%"}, {yPercent: "0", x: "-50%"})
            .to(".video2", {yPercent: "0", x: "-50%"})
            .to(".video2", {yPercent: "25", x: "-50%"})

        gsap.timeline({
            scrollTrigger: {
                trigger: ".s10",
                pin: true,
                scrub: true,
                start: "top top",
                scroller: ".scroller"
            }
        })
            .fromTo(by.current, {opacity: 0}, {opacity: 0.6})
            .fromTo(by.current, {y: "180%", opacity: 0.6}, {y: "0%", opacity: 1})

        gsap.timeline({
            scrollTrigger: {
                trigger: ".s10",
                pin: true,
                scrub: true,
                start: "top top",
                scroller: ".scroller"
            }
        })
            .fromTo(oin.current, {opacity: 0}, {opacity: 0.4})
            .fromTo(oin.current, {y: "120%", opacity: 0.4}, {y: "0%", opacity: 1})

        gsap.timeline({
            scrollTrigger: {
                trigger: ".s10",
                pin: true,
                scrub: true,
                start: "top top",
                scroller: ".scroller",
            }
        })
            .fromTo(ing.current, {opacity: 0}, {opacity: 0.8})
            .fromTo(ing.current, {y: "60%", opacity: 0.8}, {y: "0%", opacity: 1})
            .fromTo(".joiningContent", {opacity: 0}, {opacity: 1})

    }, [])

    return (
        <Section10 className="s10">
            <Section9 className="s9">
                <div className="video2 videoWrap">
                    <ReactPlayer
                        ref={player}
                        url="//vimeo.com/454924328/f8c92c0fb3"
                        className="video"
                        width="100%"
                        height="100%"
                        muted={true}
                        controls={false}
                        playsinline={true}
                        playing={playing}
                        loop={true}
                        vimeo={{
                            fullscreen: 0
                        }}
                    />
                </div>
            </Section9>
            <Container className="svgWrapper">
                <SVGWrapper>
                    <svg ref={by} id="by" width="216" height="134" viewBox="0 0 216 134" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path opacity="1"
                              d="M96.5008 96.3995C96.5008 76.7995 84.5008 65.7995 69.7008 62.9995C79.5008 57.1995 84.9008 47.5995 84.9008 35.3995C84.9008 12.9995 67.3008 0.599501 44.1008 0.599501H0.100782V134H52.9008C80.1008 134 96.5008 119.8 96.5008 96.3995ZM54.5008 40.1995C54.5008 48.5995 49.1008 53.1995 38.9008 53.1995H32.9008V27.1995H38.9008C49.1008 27.1995 54.5008 31.5995 54.5008 40.1995ZM63.3008 92.9995C63.3008 103.2 56.7008 107.4 40.3008 107.4H32.9008V78.9995H39.7008C50.9008 78.9995 53.3008 79.1995 56.9008 81.3995C60.9008 83.5995 63.3008 87.9995 63.3008 92.9995ZM215.973 0.599501H176.173L153.173 36.9995L129.173 0.599501H89.7727L135.573 69.9995V134H168.573V69.9995L215.973 0.599501Z"
                              fill="white"/>
                    </svg>
                    <div className="jWrapper">
                        <OrangeLineWrapper id="ol10">
                            <svg width="27" height="200" viewBox="0 0 27 157" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g filter="url(#filter0_orangeLine)">
                                    <path className="ol10" d="M13.4999 13.5L13.5 143.5" stroke="#FFAA00" strokeWidth="3"
                                          strokeLinecap="round"/>
                                </g>
                                <defs>
                                    <filter id="filter0_orangeLine" x="0" y="0" width="27.0001" height="157"
                                            filterUnits="userSpaceOnUse"
                                            colorInterpolationFilters="sRGB">
                                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                                        <feColorMatrix in="SourceAlpha" type="matrix"
                                                       values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                                        <feOffset/>
                                        <feGaussianBlur stdDeviation="6"/>
                                        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.234375 0 0 0 0 0 0 0 0 1 0"/>
                                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                                        <feColorMatrix in="SourceAlpha" type="matrix"
                                                       values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                                        <feOffset/>
                                        <feGaussianBlur stdDeviation="3"/>
                                        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.234375 0 0 0 0 0 0 0 0 1 0"/>
                                        <feBlend mode="normal" in2="effect1_dropShadow" result="effect2_dropShadow"/>
                                        <feColorMatrix in="SourceAlpha" type="matrix"
                                                       values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                                        <feOffset/>
                                        <feGaussianBlur stdDeviation="2"/>
                                        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.234375 0 0 0 0 0 0 0 0 1 0"/>
                                        <feBlend mode="normal" in2="effect2_dropShadow" result="effect3_dropShadow"/>
                                        <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow" result="shape"/>
                                    </filter>
                                </defs>
                            </svg>
                        </OrangeLineWrapper>
                        <svg ref={j} id="j" width="76" height="138" viewBox="0 0 76 138" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M75.9844 88.7995V0.599501H42.9844V84.9995C42.9844 101 41.5844 107.8 33.7844 107.8C27.9844 107.8 22.9844 102 20.1844 97.5995L0.584375 118.4C11.1844 131.4 21.3844 137.2 35.7844 137.2C47.7844 137.2 58.9844 132.4 66.3844 123.8C73.7844 115.4 75.9844 104 75.9844 88.7995Z"
                                fill="white"/>
                        </svg>
                    </div>

                    <svg ref={oin} id="oin" width="318" height="143" viewBox="0 0 318 143" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path opacity="1"
                              d="M140.916 71.7995C140.916 31.1995 110.916 0.799502 70.7164 0.799502C30.7164 0.799502 0.716407 31.1995 0.716407 71.7995C0.716407 112.4 30.7164 142.8 70.9164 142.8C110.916 142.8 140.916 112.4 140.916 71.7995ZM106.316 71.5995C106.316 92.3995 90.3164 109 70.7164 109C51.1164 109 35.1164 92.3995 35.1164 71.5995C35.1164 50.9995 51.1164 34.1995 70.7164 34.1995C90.3164 34.1995 106.316 50.9995 106.316 71.5995ZM178.94 138V4.5995H146.14V138H178.94ZM317.032 138V4.5995H284.032V86.1995L223.432 4.5995H190.632V138H223.432V56.3995L284.032 138H317.032Z"
                              fill="white"/>
                    </svg>

                    <svg ref={ing} id="ing" width="310" height="143" viewBox="0 0 310 143" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path opacity="1"
                              d="M33.725 138V4.5995H0.925V138H33.725ZM171.817 138V4.5995H138.817V86.1995L78.2172 4.5995H45.4172V138H78.2172V56.3995L138.817 138H171.817ZM309.469 63.7995H244.069V90.9995H271.069C268.869 106.2 260.069 114 244.869 114C225.069 114 211.069 96.5995 211.069 71.7995C211.069 48.1995 225.069 30.3995 244.069 30.3995C256.669 30.3995 265.669 37.1995 271.269 51.3995L302.669 37.7995C290.669 12.1995 272.469 0.399507 245.469 0.399507C204.669 0.399507 176.869 29.3995 176.869 71.7995C176.869 114 204.269 142.6 244.269 142.6C277.269 142.6 302.069 121.8 307.469 89.1995C309.069 79.9995 309.269 77.5995 309.469 63.7995Z"
                              fill="white"/>
                    </svg>


                </SVGWrapper>
                <div className="joiningContent container">
                    <div className="justify-content-center d-flex">
                        <div className="content">
                            <p>our 70th Anniversary Season, you’ll not only be celebrating THE MOUSETRAP’s incredible journey — you’ll be taking your place in theatre history. And in doing so, you’ll become an integral part of the global theatre community’s bright, connected future. </p>
                        </div>
                    </div>
                </div>
            </Container>
        </Section10>
    )
}

export default Section10Joining
