import React, {useEffect, useState} from "react"
import styled from "styled-components"
import Section from "components/shared/Section"
import Lottie from 'react-lottie-player'
import animationData from "../../bodymovin/web/data.json";
import gsap from "gsap";
import {Col, Container, Row} from "reactstrap";
import {media} from "utils/Media";

const settings = {
    loop: false,
    autoplay: true,
    renderer: 'svg',
};

const Section8 = styled(Section)`
    background-color: #000000;
    z-index: 3;
    position: relative;

    #web {
        width: 100%;
        overflow: hidden;
        svg {
          @media(max-width: 767px) {
            transform: translate3d(0px, 0px, 0px) scale(2) !important;
          }
        }
    }

    .webContent {
    	position: absolute;
		bottom: 30px;
		left: 28px;
        width: calc(100% - 60px);

        @media ${media.sm} {
            bottom: 20%;
            left: 50%;
            transform: translateX(-50%);
        }

		@media ${media.md} {
            bottom: initial;
            top: 50%;
            transform: translate(-50%, -50%);
		}
    }
`

const Section8GridLines = () => {

    const [animationPosition, setAnimationPosition] = useState(0);

    useEffect(() => {
        gsap.timeline({
            scrollTrigger: {
                trigger: ".s8",
                pin: true,
                scrub: true,
                start: "top top",
                end: "bottom 200px",
                scroller: ".scroller",
                onUpdate: self => {
                    setAnimationPosition(100 * self.progress + 20);
                }
                // markers: true,
            }
        })
            .fromTo("#web", {opacity: 0}, {opacity: 1}, 0.1)
            .to("#web", {opacity: 1}, 0.4)
            .fromTo(".webContent", {opacity: 0}, {opacity: 1}, 1)
    }, [])


    return (
        <Section8 className="s8">
            <div id="web">
                <Lottie
                    animationData={animationData}
                    options={settings}
                    loop={false}
                    goTo={animationPosition}
                />
            </div>
            <Container className="webContent">
                        <div className="content narrow">
                            <p>Nothing’s quite as fun as playing detective from your seat, knowing every colourful
                                character is a suspect, and trying to piece it all together yourself in real time. It’s
                                deceptively simple… and simply genius!</p>
                        </div>
            </Container>

        </Section8>
    )
}


export default Section8GridLines
