import React, {useState, useEffect, useRef} from "react"
import {Container, Row, Col} from "reactstrap"
import ReactPlayer from "react-player"
import gsap from "gsap"
import styled from "styled-components"
import Section from "../shared/Section"
import {media} from "utils/Media";
import Lottie from 'react-lottie-player'
import animationDataDesktop from "../../bodymovin/magnify-desktop/data.json";
import animationDataTablet from "../../bodymovin/magnify-tablet/data.json";
import animationDataMobile from "../../bodymovin/magnify-mobile/data.json";

const settings = {
    loop: false,
    autoplay: true,
    renderer: 'svg',
};

const Section6 = styled(Section)`
    background-color: #000;
    z-index: 4;
    overflow: hidden;
   align-items: center;

    @media ${media.md} {
        align-items: center;
    }

    .magnifyWrapper {
        position: absolute;
        top: 50%;
        left: 28px;
        width: calc(100% - 60px);
        transform: translateY(-50%);

        @media ${media.md} {
            width: 700px;
            left: 50%;
            transform: translate(-50%, -50%);
            top: calc(50% + 50px);
        }

        @media ${media.lg} {
            width: 700px;
            top: 50%;
        }
        @media(max-width: 767px) {
          top: calc(50% + 120px);
        }
    }

    .magnifyContent {
        opacity: 0;
        position: relative;
        z-index: 2;
        /* transition: opacity 0.4s ease; */
    }

    .title {
		@media ${media.md} {
			//font-size: 15rem;
		}
    }
`

const LottieWrapper = styled(Section)`
    @media ${media.md} {
        min-height: 70vw;
        align-items: flex-start !important;
    }
    > div {
        position: fixed;
        top: 0;
        left: 0;

        @media ${media.sm} {
            top: 10%;
        }

        @media ${media.md} {
            position: relative;
            top: initial;
            left: initial;
        }
    }
`
const Section5 = styled(Section)`
    min-height: 40vw;
    overflow: hidden;
    z-index: 4;
    position: absolute;
    top: 0;
    width: 100%;

    .videoWrap {
        position: absolute;
        left: 50%;
        top: -25%;
        /* transform: translateX(-50%); */
        width: 150%;
        height: 150%;
        pointer-events: none;
        z-index: 10;
    }
`

const OrangeLineWrapper = styled.div`
    position: absolute;
	  transform: translateX(-50%);
    bottom: calc(0vh - 80px);
    left: 50%;

    @media ${media.md} {
        bottom: calc(0vh - 50px);
    }
    @media(max-width: 767px) {
        display: none;
    }
`

const SvgWrapper = styled.div`
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 5;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
    min-width: 150vw;
    display: none;

    #mapBorder1 {
        max-width: 100%;
        opacity: 0;
    }
    @media ${media.sm} {
        min-width: 125vw;
    }
    @media ${media.md} {
        min-width: 120vw;
    }
    @media ${media.lg} {
        min-width: 575px;
    }

    @media(max-width: 767px) {
        flex-direction: column;
        display:flex !important;
        top: calc(50% + 120px);
    }

    .ml3SVG {
        margin-bottom: -20px;

        @media ${media.lg} {
            margin-bottom: -26px;
        }
    }
    .ml4SVG {
        margin-top: -20px;

        @media ${media.lg} {
            margin-top: -26px;
        }
    }
`

const Section6Magnify = () => {
    const [playing, setPlaying] = useState(false);
    const player = useRef(null)
    const magnify = useRef(null)
    const magnifyText = useRef(null)
    const magnifyContent = useRef(null)
    const [animationPosition, setAnimationPosition] = useState(0);

    useEffect(() => {
        // Video animation
        gsap.timeline({
            scrollTrigger: {
                trigger: ".s5",
                scrub: true,
                start: "top top",
                end: "bottom top",
                scroller: ".scroller",
                // markers: true,
            }
        })
            .to(".s5", {yPercent: "-100"})

        gsap.timeline({
            scrollTrigger: {
                trigger: ".s5",
                scrub: true,
                start: "top bottom",
                end: "bottom top",
                scroller: ".scroller",
                // markers: true,
                // onUpdate: self =>  this.player.seekTo(parseFloat(self.progress.toFixed(3))),
                onEnter: () => setPlaying(true),
                onEnterBack: () => setPlaying(true),
                onLeaveBack: () => setPlaying(false),
                onLeave: () => setPlaying(false)
            }
        })
            .fromTo(".video1", {yPercent: "-25", x: "-50%"}, {yPercent: "0", x: "-50%"})
            .to(".video1", {yPercent: "0", x: "-50%"})
            .to(".video1", {yPercent: "25", x: "-50%"})


        // Magnify animation
        gsap.timeline({
            scrollTrigger: {
                trigger: ".s6",
                pin: true,
                scrub: true,
                start: "top top",
                end: "+=150%",
                scroller: ".scroller",
                onUpdate: self => {
                    const num = self.progress.toFixed(3)
                    // const magnifyPos = magnify.current.getBoundingClientRect()
                    // magnifyText.current.style.left = -(magnifyPos.left - 100) + "px"

                    setAnimationPosition(100 * self.progress);

                    // Fade in text 0.6 onwards
                    if (magnifyContent.current) {
                        if (num >= 0.6) {
                            magnifyContent.current.style.opacity = num - (1 - num)
                        } else {
                            magnifyContent.current.style.opacity = 0
                        }
                    }
                }
            }
        })
            .fromTo(".ml3", {drawSVG: "0"}, {drawSVG: "100%"}, 0.1)
            .to(magnify.current, {x: "0", duration: .5}) // delay magnify animation so its in display
            .fromTo(magnify.current, {x: "0%"}, {x: "180%"})
            .fromTo("#mapBorder1", {opacity: 0}, {opacity: 1}, 0.4)
            .fromTo(".mapBorder1", {drawSVG: "50% 50%"}, {drawSVG: "0% 100%"}, 0.4)
            .fromTo(".mapOutline1", {opacity: "0"}, {opacity: "1"}, 0.4)
            .fromTo(".ml4", {drawSVG: "0"}, {drawSVG: "100%"}, 1)

        gsap.timeline({
            scrollTrigger: {
                trigger: "#ol2",
                pin: false,
                scrub: true,
                start: "top top",
                end: "bottom top",
                scroller: ".scroller",
                markers: false,
            }
        })
            // .fromTo("#ol2", {height: 0}, {height: "200px"})
            // .to("#ol2", {ease: Circ.easeIn, bottom: "100vh", duration: 2})
    }, [])

    return (
        <Section6 className="s6">
            <Section5 className="s5">
                <div className="video1 videoWrap">
                    <ReactPlayer
                        ref={player}
                        url="//vimeo.com/454924328/f8c92c0fb3"
                        className="video"
                        width="100%"
                        height="100%"
                        muted={true}
                        controls={false}
                        playsinline={true}
                        playing={playing}
                        loop={true}
                    />
                </div>
            </Section5>
            <Container className="s6Container position-relative" fluid>
                {/* <h5 className="text-center pb-4 title">
                    FOR SEVEN DECADES
                </h5> */}

                {/* <MagnifyGlass ref={magnify}>
                    <div className="magnifySpy">
                        <div className="magnifyText" ref={magnifyText}>
                            FOR&nbsp;SEVEN&nbsp;DECADES
                        </div>
                    </div>
                    <img src={magnifyGlass} className="magnifyImg" alt=""/>
                </MagnifyGlass> */}

                <div className="overflow-hidden">
                    <div className="d-block d-sm-none">
                        <LottieWrapper>
                            <Lottie
                                animationData={animationDataMobile}
                                options={settings}
                                loop={false}
                                goTo={animationPosition}
                            />
                        </LottieWrapper>
                    </div>

                    <div className="d-none d-sm-block d-lg-none">
                        <LottieWrapper>
                            <Lottie
                                animationData={animationDataTablet}
                                options={settings}
                                loop={false}
                                goTo={animationPosition}
                            />
                        </LottieWrapper>
                    </div>
                    <div className="d-none d-lg-block w-100">
                        <LottieWrapper>
                            <Lottie
                                animationData={animationDataDesktop}
                                options={settings}
                                loop={false}
                                goTo={animationPosition}
                            />
                        </LottieWrapper>
                    </div>
                </div>
                <SvgWrapper>
                    <svg className="ml3SVG" width="27" height="157" viewBox="0 0 27 157" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <g filter="url(#ml3)">
                            <path className="ml3" d="M13.4999 13.5L13.5 143.5" stroke="#FFAA00" strokeWidth="3"
                                  strokeLinecap="round"/>
                        </g>
                        <defs>
                            <filter id="ml3" x="0" y="0" width="27.0001" height="157" filterUnits="userSpaceOnUse"
                                    colorInterpolationFilters="sRGB">
                                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                                <feColorMatrix in="SourceAlpha" type="matrix"
                                               values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                                <feOffset/>
                                <feGaussianBlur stdDeviation="6"/>
                                <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.234375 0 0 0 0 0 0 0 0 1 0"/>
                                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                                <feColorMatrix in="SourceAlpha" type="matrix"
                                               values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                                <feOffset/>
                                <feGaussianBlur stdDeviation="3"/>
                                <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.234375 0 0 0 0 0 0 0 0 1 0"/>
                                <feBlend mode="normal" in2="effect1_dropShadow" result="effect2_dropShadow"/>
                                <feColorMatrix in="SourceAlpha" type="matrix"
                                               values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                                <feOffset/>
                                <feGaussianBlur stdDeviation="2"/>
                                <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.234375 0 0 0 0 0 0 0 0 1 0"/>
                                <feBlend mode="normal" in2="effect2_dropShadow" result="effect3_dropShadow"/>
                                <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow" result="shape"/>
                            </filter>
                        </defs>
                    </svg>

                    <svg id="mapBorder1" viewBox="0 0 1228 732" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <g filter="url(#filter0_ddd)">
                            <path className="mapBorder1" d="M614 718H14C14 443.071 14 14 14 14H1214V718H614.5"
                                  stroke="#FFAA00" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                        </g>
                        <defs>
                            <filter id="filter0_ddd" x="0.5" y="0.5" width="1227" height="731"
                                    filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                                <feColorMatrix in="SourceAlpha" type="matrix"
                                               values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                                <feOffset/>
                                <feGaussianBlur stdDeviation="6"/>
                                <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.234375 0 0 0 0 0 0 0 0 1 0"/>
                                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                                <feColorMatrix in="SourceAlpha" type="matrix"
                                               values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                                <feOffset/>
                                <feGaussianBlur stdDeviation="3"/>
                                <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.234375 0 0 0 0 0 0 0 0 1 0"/>
                                <feBlend mode="normal" in2="effect1_dropShadow" result="effect2_dropShadow"/>
                                <feColorMatrix in="SourceAlpha" type="matrix"
                                               values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                                <feOffset/>
                                <feGaussianBlur stdDeviation="2"/>
                                <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.234375 0 0 0 0 0 0 0 0 1 0"/>
                                <feBlend mode="normal" in2="effect2_dropShadow" result="effect3_dropShadow"/>
                                <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow" result="shape"/>
                            </filter>
                        </defs>
                    </svg>
                    <svg className="ml4SVG" width="27" height="157" viewBox="0 0 27 157" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <g filter="url(#ml4)">
                            <path className="ml4" d="M13.4999 13.5L13.5 143.5" stroke="#FFAA00" strokeWidth="3"
                                  strokeLinecap="round"/>
                        </g>
                        <defs>
                            <filter id="ml4" x="0" y="0" width="27.0001" height="157" filterUnits="userSpaceOnUse"
                                    colorInterpolationFilters="sRGB">
                                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                                <feColorMatrix in="SourceAlpha" type="matrix"
                                               values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                                <feOffset/>
                                <feGaussianBlur stdDeviation="6"/>
                                <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.234375 0 0 0 0 0 0 0 0 1 0"/>
                                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                                <feColorMatrix in="SourceAlpha" type="matrix"
                                               values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                                <feOffset/>
                                <feGaussianBlur stdDeviation="3"/>
                                <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.234375 0 0 0 0 0 0 0 0 1 0"/>
                                <feBlend mode="normal" in2="effect1_dropShadow" result="effect2_dropShadow"/>
                                <feColorMatrix in="SourceAlpha" type="matrix"
                                               values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                                <feOffset/>
                                <feGaussianBlur stdDeviation="2"/>
                                <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.234375 0 0 0 0 0 0 0 0 1 0"/>
                                <feBlend mode="normal" in2="effect2_dropShadow" result="effect3_dropShadow"/>
                                <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow" result="shape"/>
                            </filter>
                        </defs>
                    </svg>
                </SvgWrapper>
                <div className="justify-content-center magnifyWrapper d-flex">
                        <div ref={magnifyContent} className="magnifyContent">
                            <p>
                                the London production has provided an exceptional benchmark for the universal magic of
                                well-crafted live theatre. We now invite you to be part of this amazing legacy by
                                presenting your own production of THE MOUSETRAP, envisioned and brought to life by the
                                extraordinary local artists who light up your stages.
                            </p>
                        </div>
                </div>
            </Container>
            <OrangeLineWrapper id="ol2">
                <svg width="27" height="200" viewBox="0 0 27 157" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g filter="url(#filter0_orangeLine)">
                        <path className="ol2" d="M13.4999 13.5L13.5 143.5" stroke="#FFAA00" strokeWidth="3"
                              strokeLinecap="round"/>
                    </g>
                    <defs>
                        <filter id="filter0_orangeLine" x="0" y="0" width="27.0001" height="157"
                                filterUnits="userSpaceOnUse"
                                colorInterpolationFilters="sRGB">
                            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                            <feColorMatrix in="SourceAlpha" type="matrix"
                                           values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                            <feOffset/>
                            <feGaussianBlur stdDeviation="6"/>
                            <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.234375 0 0 0 0 0 0 0 0 1 0"/>
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                            <feColorMatrix in="SourceAlpha" type="matrix"
                                           values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                            <feOffset/>
                            <feGaussianBlur stdDeviation="3"/>
                            <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.234375 0 0 0 0 0 0 0 0 1 0"/>
                            <feBlend mode="normal" in2="effect1_dropShadow" result="effect2_dropShadow"/>
                            <feColorMatrix in="SourceAlpha" type="matrix"
                                           values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                            <feOffset/>
                            <feGaussianBlur stdDeviation="2"/>
                            <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.234375 0 0 0 0 0 0 0 0 1 0"/>
                            <feBlend mode="normal" in2="effect2_dropShadow" result="effect3_dropShadow"/>
                            <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow" result="shape"/>
                        </filter>
                    </defs>
                </svg>
            </OrangeLineWrapper>
        </Section6>
    )
}

export default Section6Magnify
