import React, {useEffect} from "react"
import {StaticQuery, graphql} from "gatsby"
import {media} from "utils/Media"
import Img from "gatsby-image"
import gsap from "gsap"
import styled from "styled-components"
import Section from "../shared/Section"
import mapOutline from "images/map-outline.png"
import mapPins from "images/map-pins.png"

const Section4 = styled(Section)`
    /* background-color: #333; */
    z-index: 3;
    overflow: hidden;
`

const Content = styled(Section)`
   display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 80vw;
    max-width: 1200px;
    margin: 0 auto;
    overflow: hidden;

    #mapBorder {
        max-width: 100%;
        opacity: 0;
    }

    .woodBg {
        position: absolute !important;
        top: 50%;
        left: -25%;
        transform: translateY(-50%);
        width: 150%;

        @media ${media.md} {
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100% !important;
        }
        @media ${media.lg} {
            width: calc(100% - 40px) !important;
        }
    }

    .mapText {
        text-align: left;
        z-index: 4;
        position: relative;
        padding: 0 28px;

        @media ${media.md} {
            padding: 0 15px;
        }

        p {
            font-size: 16px;
            margin: 0;

            @media ${media.md} {
                font-size: 22px;
                line-height: 140%;
            }
        }
    }
    .mapPins {
        position: absolute;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        top: 55%;

        @media ${media.md} {
            width: calc(100% - 80px);
        }
    }
`

const Relative = styled.div`
    position: relative;
    padding: 0;
    @media ${media.md} {
        padding: 0 40px;
    }

`

const ContentWrapper = styled.div`
    //position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    position: absolute;
    z-index: 4;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
`

const SvgWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 5;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
    min-width: 150vw;

    @media ${media.sm} {
        min-width: 125vw;
    }
    @media ${media.md} {
        min-width: 120vw;
    }
    @media ${media.lg} {
        min-width: 575px;
    }

    @media(max-width: 767px) {
        flex-direction: column;
    }

    .ol3SVG {
        margin-bottom: -20px;

        @media ${media.lg} {
            margin-bottom: -26px;
        }
    }
    .ol4SVG {
        margin-top: -20px;

        @media ${media.lg} {
            margin-top: -26px;
        }
    }
`

const Section4Map = (props) => {

    useEffect(() => {
        // Map
        gsap.timeline({
            scrollTrigger: {
                trigger: ".s4",
                pin: true,
                scrub: true,
                start: "top top",
                 end: "bottom top",
                scroller: ".scroller",
                // markers: true,
            }
        })
            .fromTo(".ol3", {drawSVG: "0"}, {drawSVG: "100%"}, 0.1)
            .fromTo("#mapBorder", {opacity: 0}, {opacity: 1}, 0.4)
            .fromTo(".mapBorder", {drawSVG: "50% 50%"}, {drawSVG: "0% 100%"}, 0.4)
            .fromTo(".mapOutline", {opacity: "0"}, {opacity: "1"}, 0.4)
            .fromTo(".mapText", {opacity: "0"}, {opacity: "1"}, 0.5)
            .fromTo(".woodBg", {opacity: "0"}, {opacity: "1"}, 0.6)
            .fromTo(".mapPins", {opacity: "0"}, {opacity: "1"}, 0.7)
            .fromTo(".ol4", {drawSVG: "0"}, {drawSVG: "100%"}, 1)
    }, [])

    return (
        <Section4 className="s4">
            <Content>
                <SvgWrapper>
                    <svg className="ol3SVG" width="27" height="157" viewBox="0 0 27 157" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <g filter="url(#ol3)">
                            <path className="ol3" d="M13.4999 13.5L13.5 143.5" stroke="#FFAA00" strokeWidth="3"
                                  strokeLinecap="round"/>
                        </g>
                        <defs>
                            <filter id="ol3" x="0" y="0" width="27.0001" height="157" filterUnits="userSpaceOnUse"
                                    colorInterpolationFilters="sRGB">
                                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                                <feColorMatrix in="SourceAlpha" type="matrix"
                                               values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                                <feOffset/>
                                <feGaussianBlur stdDeviation="6"/>
                                <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.234375 0 0 0 0 0 0 0 0 1 0"/>
                                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                                <feColorMatrix in="SourceAlpha" type="matrix"
                                               values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                                <feOffset/>
                                <feGaussianBlur stdDeviation="3"/>
                                <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.234375 0 0 0 0 0 0 0 0 1 0"/>
                                <feBlend mode="normal" in2="effect1_dropShadow" result="effect2_dropShadow"/>
                                <feColorMatrix in="SourceAlpha" type="matrix"
                                               values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                                <feOffset/>
                                <feGaussianBlur stdDeviation="2"/>
                                <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.234375 0 0 0 0 0 0 0 0 1 0"/>
                                <feBlend mode="normal" in2="effect2_dropShadow" result="effect3_dropShadow"/>
                                <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow" result="shape"/>
                            </filter>
                        </defs>
                    </svg>

                    <svg id="mapBorder" viewBox="0 0 1228 732" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <g filter="url(#filter0_ddd)">
                            <path className="mapBorder" d="M614 718H14C14 443.071 14 14 14 14H1214V718H614.5"
                                  stroke="#FFAA00" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                        </g>
                        <defs>
                            <filter id="filter0_ddd" x="0.5" y="0.5" width="1227" height="731"
                                    filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                                <feColorMatrix in="SourceAlpha" type="matrix"
                                               values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                                <feOffset/>
                                <feGaussianBlur stdDeviation="6"/>
                                <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.234375 0 0 0 0 0 0 0 0 1 0"/>
                                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                                <feColorMatrix in="SourceAlpha" type="matrix"
                                               values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                                <feOffset/>
                                <feGaussianBlur stdDeviation="3"/>
                                <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.234375 0 0 0 0 0 0 0 0 1 0"/>
                                <feBlend mode="normal" in2="effect1_dropShadow" result="effect2_dropShadow"/>
                                <feColorMatrix in="SourceAlpha" type="matrix"
                                               values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                                <feOffset/>
                                <feGaussianBlur stdDeviation="2"/>
                                <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.234375 0 0 0 0 0 0 0 0 1 0"/>
                                <feBlend mode="normal" in2="effect2_dropShadow" result="effect3_dropShadow"/>
                                <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow" result="shape"/>
                            </filter>
                        </defs>
                    </svg>
                    <svg className="ol4SVG" width="27" height="157" viewBox="0 0 27 157" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <g filter="url(#ol4)">
                            <path className="ol4" d="M13.4999 13.5L13.5 143.5" stroke="#FFAA00" strokeWidth="3"
                                  strokeLinecap="round"/>
                        </g>
                        <defs>
                            <filter id="ol4" x="0" y="0" width="27.0001" height="157" filterUnits="userSpaceOnUse"
                                    colorInterpolationFilters="sRGB">
                                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                                <feColorMatrix in="SourceAlpha" type="matrix"
                                               values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                                <feOffset/>
                                <feGaussianBlur stdDeviation="6"/>
                                <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.234375 0 0 0 0 0 0 0 0 1 0"/>
                                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                                <feColorMatrix in="SourceAlpha" type="matrix"
                                               values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                                <feOffset/>
                                <feGaussianBlur stdDeviation="3"/>
                                <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.234375 0 0 0 0 0 0 0 0 1 0"/>
                                <feBlend mode="normal" in2="effect1_dropShadow" result="effect2_dropShadow"/>
                                <feColorMatrix in="SourceAlpha" type="matrix"
                                               values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                                <feOffset/>
                                <feGaussianBlur stdDeviation="2"/>
                                <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.234375 0 0 0 0 0 0 0 0 1 0"/>
                                <feBlend mode="normal" in2="effect2_dropShadow" result="effect3_dropShadow"/>
                                <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow" result="shape"/>
                            </filter>
                        </defs>
                    </svg>
                </SvgWrapper>
                <ContentWrapper>
                    <Img fluid={props.data.woodBg.childImageSharp.fluid} alt="" className="woodBg"/>
                        <p className="mapText narrow">
                            We will be licensing 70 productions of THE MOUSETRAP in countries across the globe, in as
                            many languages as possible, as part of our 70th Anniversary Season.
                        </p>
                        <Relative>
                            <img src={mapOutline} className="mapOutline" alt=""/>
                            <img src={mapPins} className="mapPins" alt=""/>
                        </Relative>
                </ContentWrapper>
            </Content>
        </Section4>
    )
}

export default () => (
    <StaticQuery
        query={graphql`
            query {
                woodBg: file(relativePath: { eq: "wood-bg.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 1920, quality: 85) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
		`}
        render={data => (
            <Section4Map data={data}/>
        )}
    />
)
