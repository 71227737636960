import React, {useEffect, useState} from "react"
import {Container, Row, Col} from "reactstrap"
import styled from "styled-components"
import gsap, {Circ} from "gsap"
import Section from "components/shared/Section"
import {media} from "utils/Media"

const Section3 = styled(Section)`
    background: transparent;
    z-index: 4;
    overflow: hidden;
`

const YearWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;

    section {
        position: absolute;
        top: 50%;
        left: 28px;
        width: calc(100% - 90px);
        transform: translateY(-50%);

        @media ${media.sm} {
            transform: translate(-50%, -50%);
            width: 500px;
            left: 50%;
        }

        @media ${media.lg} {
            width: 700px;
        }
    }

    .year {
        /* border: 3px solid;
        background: linear-gradient(to bottom, #FF3C00 0%, #3D0E00 100%);
        background-clip: text;
        -webkit-text-stroke: 4px transparent; */
        font-family: 'RennerArcBold', ${props => props.theme.font.family.body};
        background: -webkit-linear-gradient(-86deg, #FF3C00 0%, #3D0E00 100%);
        -webkit-background-clip: text;
        -webkit-text-stroke: 10px transparent;
        color: black;
        font-size: 41vw;
        position: absolute;
        top: 50%;
        width: 100%;
        left: 50%;
        transform: translate(-50%, -50%);

        @media (max-width:767px) {
            top: 30% !important;
        }
        @media ${media.md} {
            font-size: 43vw;
            left: 50%;
        }
    }

    .yearText {
        opacity: 0;
    }

    .yearContent {
        max-width: 500px;
        position: relative;

        @media ${media.md} {
            position: absolute;
            top: 50%;
            left: 15px;
            width: 100%;
            transform: translateY(-50%);
            max-width: 70%;
            width: initial;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
`

const OrangeLineWrapper = styled.div`
    position: absolute;
	transform: translateX(-50%);
    bottom: calc(0vh - 50px);
    left: 50%;
`

const SectionDateCount = (props) => {
    const [year, setYear] = useState(1952);

    useEffect(() => {
        let num = {var: 1952};

        gsap.timeline({
            scrollTrigger: {
                trigger: ".s3",
                pin: true,
                scrub: true,
                start: "top top",
                // end: "bottom 100px",
                scroller: ".scroller",
                // markers: true,
            }
        })
            .to(".yearText", {opacity: 1, duration: 10})
            .to(num, {
                var: 2022, duration: 10, ease: "none", onUpdate: () => {
                    setYear(num.var.toFixed())
                }
            }, 5)
            .to('#yearWrap', {autoAlpha: 0, duration: 3})
        // .to({}, {delay: 5, duration: 2})


        gsap.timeline({
            scrollTrigger: {
                trigger: ".s3",
                pin: true,
                scrub: true,
                start: "top top",
                scroller: ".scroller",
                // markers: true,
            }
        })
            .to("#firstDiv", {opacity: 1, duration: 5})
            .to("#firstDiv", {opacity: 0, duration: 1})
            .to("#secondDiv", {opacity: 1, duration: 3})
            .to("#secondDiv", {opacity: 0, duration: 2})

        gsap.timeline({
            scrollTrigger: {
                trigger: "#ol1",
                pin: false,
                scrub: true,
                start: "top top",
                end: "300px top",
                scroller: ".scroller",
                // markers: true,
            }
        })
            // .fromTo("#ol1", {height: 0}, {height: "200px"})
            .to("#ol1", {ease: Circ.easeIn, bottom: "100vh", duration: 2})

        gsap.timeline({
            scrollTrigger: {
                trigger: "#ol1",
                pin: false,
                scrub: true,
                start: "top top",
                end: "300px top",
                scroller: ".scroller",
                // markers: true,
            }
        })
            // .fromTo("#ol1", {height: 0}, {height: "200px"})
            .to("#ol1", {ease: Circ.easeIn, bottom: "100vh", duration: 2})

    }, [])

    return (
        <Section3 className="s3">
            <YearWrap id="yearWrap">
                <div className="year">
                    {year}
                </div>
                <Container className="yearContent">
                    <div className="yearText narrow justify-content-center">
                        <div className="content">
                            <section id="firstDiv">
                                <div className="section__content">
                                    <p>Agatha Christie’s THE MOUSETRAP is the world’s most successful and
                                        longest-running play. Since opening in London’s West End in 1952, this
                                        classic murder mystery has had millions of theatregoers on the edge of their
                                        seats. </p>
                                </div>
                            </section>
                            <section id="secondDiv" style={{opacity: 0}}>
                                <div className="section__content">
                                    <p>In 2022, THE MOUSETRAP will celebrate an astonishing 70 YEARS onstage. To
                                        mark the occasion, we are inviting the world’s most esteemed theatres and
                                        producers to join us in an epic global event. </p>
                                </div>
                            </section>
                        </div>
                    </div>
                </Container>
            </YearWrap>
            <OrangeLineWrapper id="ol1">
                <svg width="27" height="200" viewBox="0 0 27 157" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g filter="url(#filter0_orangeLine)">
                        <path className="ol2" d="M13.4999 13.5L13.5 143.5" stroke="#FFAA00" strokeWidth="3"
                              strokeLinecap="round"/>
                    </g>
                    <defs>
                        <filter id="filter0_orangeLine" x="0" y="0" width="27.0001" height="157"
                                filterUnits="userSpaceOnUse"
                                colorInterpolationFilters="sRGB">
                            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                            <feColorMatrix in="SourceAlpha" type="matrix"
                                           values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                            <feOffset/>
                            <feGaussianBlur stdDeviation="6"/>
                            <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.234375 0 0 0 0 0 0 0 0 1 0"/>
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                            <feColorMatrix in="SourceAlpha" type="matrix"
                                           values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                            <feOffset/>
                            <feGaussianBlur stdDeviation="3"/>
                            <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.234375 0 0 0 0 0 0 0 0 1 0"/>
                            <feBlend mode="normal" in2="effect1_dropShadow" result="effect2_dropShadow"/>
                            <feColorMatrix in="SourceAlpha" type="matrix"
                                           values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                            <feOffset/>
                            <feGaussianBlur stdDeviation="2"/>
                            <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.234375 0 0 0 0 0 0 0 0 1 0"/>
                            <feBlend mode="normal" in2="effect2_dropShadow" result="effect3_dropShadow"/>
                            <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow" result="shape"/>
                        </filter>
                    </defs>
                </svg>
            </OrangeLineWrapper>

        </Section3>
    )
}

export default SectionDateCount
