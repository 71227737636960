import styled from "styled-components"

const Section = styled.div`
    min-height: 100vh;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
`

export default Section