import React, {useEffect} from "react"
import styled from "styled-components"
import gsap from "gsap"
import {StaticQuery, graphql} from "gatsby"
import {Container, Row, Col} from "reactstrap"
import Img from "gatsby-image"
import Section from "components/shared/Section"
import {media} from "utils/Media";

const Section7 = styled(Section)`
    overflow: hidden;
    z-index: 5;
    top: 10vw;
    display: block;
    padding-top: 0;
    max-width: 100vw;
    min-height: 100vh;
    height: 120vh;

    .woodBg2 {
        position: absolute !important;
        top: 50%;
        left: -25%;
        transform: translateY(-50%);
        width: 150%;
        
        @media ${media.md} {
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100% !important;
        }
    }

    #pen {
        
    }
    .content {
        max-width: 700px;
        margin: 0 auto;
    }
    

    .penContent {
        position: absolute;
        top: calc(50% - 30px);
        left: 28px;
        width: 100%;
        transform: translateY(-50%);
        
        @media ${media.md} {
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
    
    .penBelow {
        position: absolute;
        bottom: 0;
        left: calc(50%);
        transform: translateX(-50%);
        z-index: 10;
    }
`

const SVGWrapper = styled.div`
    max-width: 1100px;
    margin: 0 auto;
    position: absolute;
    top: calc(50%);
    left: calc(50% - 24px);
    transform: translate(-50%, -50%);
    
    svg {
        width: 1150px;
    }
`

const Section7Pen = (props) => {

    useEffect(() => {
        // Pen line
        gsap.timeline({
            scrollTrigger: {
                trigger: ".s7",
                scrub: 1,
                pin: false,
                start: "top 30%",
                end: "+=50%",
                // markers: {startColor: "pink", endColor: "yellow"},
                scroller: ".scroller",
            }
        })
            .fromTo(".pen", {drawSVG: "0% 0%"}, {drawSVG: "0% 100%"}, 0)
            .fromTo(".penContent", {opacity: "0"}, {opacity: "1"}, 0)
            .fromTo(".woodBg2", {opacity: "0"}, {opacity: "1"}, 0.5)
            .fromTo(".penLine", {drawSVG: "0% 0%"}, {drawSVG: "0% 100%"}, 0.8)
            .fromTo(".penBelow", {opacity: "0"}, {opacity: "1"}, 2)
    }, [])

    return (
        <Section7 className="s7">
            <Img fluid={props.data.woodBg.childImageSharp.fluid} alt="" className="woodBg2"/>

            <SVGWrapper>
                <svg id="pen" width="1120" height="1120" viewBox="0 0 1120 1120" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0)">
                        <g filter="url(#filter0_ddd)">
                            <path className="pen"
                                  d="M563.5 8V81H590.5C590.5 102 590.341 129.12 594.5 149.5C599.5 174 604.5 185.333 606.5 191C601.5 197 589.9 213.2 583.5 230C577.1 246.8 572.167 267 570.5 275H563.5V191C565.833 189.833 570.5 186.1 570.5 180.5C570.5 174.9 566.5 170.5 560.5 170.5C554.5 170.5 550.5 174.5 550.5 180.5C550.5 186.5 554.5 189.5 556.5 191V275H549.5C546.333 261.167 538.5 230.9 532.5 220.5C526.5 210.1 517.667 196.5 514 191C517.333 180.833 524.4 157.9 526 147.5C527.6 137.1 530.5 105 529.5 81"
                                  stroke="#FFAA00" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                        </g>
                        <g filter="url(#filter1_ddd)">
                            <path className="penLine"
                                  d="M559.679 277C565.015 285.146 568.583 303.609 538.17 312.298C500.155 323.158 347.092 277 261.056 277C175.021 277 64.9752 312.298 26.9596 433.124C-11.684 555.947 36.9638 666.632 86.9843 666.632C137.005 666.632 159.014 575.672 98.9893 548.52C42.9662 523.178 4.95048 640.837 36.9637 745.373C68.9769 849.908 209.035 867.557 280.064 826.829C351.093 786.101 500.155 733.154 546.174 745.373C582.989 755.147 559.679 788.816 560.18 826.829C560.68 864.842 560.18 911 560.18 911"
                                  stroke="#FFAA00" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                        </g>
                    </g>
                    <defs>
                        <filter id="filter0_ddd" x="500.5" y="-5.5" width="119.5" height="294"
                                filterUnits="userSpaceOnUse"
                                colorInterpolationFilters="sRGB">
                            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                            <feColorMatrix in="SourceAlpha" type="matrix"
                                           values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                            <feOffset/>
                            <feGaussianBlur stdDeviation="6"/>
                            <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.234375 0 0 0 0 0 0 0 0 1 0"/>
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                            <feColorMatrix in="SourceAlpha" type="matrix"
                                           values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                            <feOffset/>
                            <feGaussianBlur stdDeviation="3"/>
                            <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.234375 0 0 0 0 0 0 0 0 1 0"/>
                            <feBlend mode="normal" in2="effect1_dropShadow" result="effect2_dropShadow"/>
                            <feColorMatrix in="SourceAlpha" type="matrix"
                                           values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                            <feOffset/>
                            <feGaussianBlur stdDeviation="2"/>
                            <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.234375 0 0 0 0 0 0 0 0 1 0"/>
                            <feBlend mode="normal" in2="effect2_dropShadow" result="effect3_dropShadow"/>
                            <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow" result="shape"/>
                        </filter>
                        <filter id="filter1_ddd" x="-0.5" y="263.5" width="581" height="661"
                                filterUnits="userSpaceOnUse"
                                colorInterpolationFilters="sRGB">
                            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                            <feColorMatrix in="SourceAlpha" type="matrix"
                                           values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                            <feOffset/>
                            <feGaussianBlur stdDeviation="6"/>
                            <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.234375 0 0 0 0 0 0 0 0 1 0"/>
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                            <feColorMatrix in="SourceAlpha" type="matrix"
                                           values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                            <feOffset/>
                            <feGaussianBlur stdDeviation="3"/>
                            <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.234375 0 0 0 0 0 0 0 0 1 0"/>
                            <feBlend mode="normal" in2="effect1_dropShadow" result="effect2_dropShadow"/>
                            <feColorMatrix in="SourceAlpha" type="matrix"
                                           values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                            <feOffset/>
                            <feGaussianBlur stdDeviation="2"/>
                            <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.234375 0 0 0 0 0 0 0 0 1 0"/>
                            <feBlend mode="normal" in2="effect2_dropShadow" result="effect3_dropShadow"/>
                            <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow" result="shape"/>
                        </filter>
                        <clipPath id="clip0">
                            <rect width="1120" height="920" fill="white"/>
                        </clipPath>
                    </defs>
                </svg>
            </SVGWrapper>


            <div className="penContent narrow">
                <div className="yearText justify-content-center d-flex">
                        <div className="content">
                            <p>Agatha Christie is the world’s best-selling novelist of all time, with an estimated 2
                                billion books sold. Besides having written the world’s longest-running play, she is also
                                the only female playwright to have ever had three plays running simultaneously in
                                London’s West End. Her books and plays have been adapted into countless TV series and
                                award-winning films including Death on the Nile, which returns later this year in a
                                remake starring Kenneth Branagh and Annette Bening.</p>

                            <p className="d-none d-lg-block">And yet over the years, THE MOUSETRAP has become more than just a play. It has evolved
                                into a can’t-miss destination for visitors from every corner of the globe, a joyful rite
                                of passage for theatregoers of all kinds, and an enduring family tradition shared across
                                generations. </p>

                        </div>
                </div>
            </div>

            <Container className="penBelow d-none d-lg-block">
                <Row className="yearText justify-content-center">
                    <Col md={10} lg={8}>
                        <div className="content">
                            <p>Perhaps the reason THE MOUSETRAP appeals to a wide range of audiences and age groups lies
                                in its clever dramatic formula. The iconic set-up, which has inspired countless other
                                murder mysteries, puts audience members in the position of not just passive observers,
                                but active participants.</p>
                        </div>
                    </Col>
                </Row>
            </Container>

        </Section7>
    )
}


export default () => (
    <StaticQuery
        query={graphql`
            query {
                woodBg: file(relativePath: { eq: "wood-bg.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 1920, quality: 85) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
		`}
        render={data => (
            <Section7Pen data={data}/>
        )}
    />
)